import React, { memo } from 'react'

declare global {
    interface Window {
        updateDOM?: () => void
    }
}

// type Theme = 'light' | 'light-new' | 'light-contrast' | 'dark'

// let updateDOM: (() => void) | undefined

const STORAGE_KEY = 'bbx-theme'

/**
 * restores the selected theme from localStorage.
 **/
export const ThemeSetter = () => {
    // TODO change from cookie to localStorage as soon as the theme selector is implemented on the frontend
    //
    // const [theme, setTheme] = useState<Theme>(
    //     () => ((typeof localStorage !== 'undefined' && localStorage.getItem(STORAGE_KEY)) ?? 'light') as Theme,
    // )

    // useEffect(() => {
    //     // store global functions to local variables to avoid any interference
    //     updateDOM = window.updateDOM
    //     const storageEventListener = (e: StorageEvent): void => {
    //         if (e.key === STORAGE_KEY) {
    //             setTheme(e.newValue as Theme)
    //         }
    //     }
    //     // sync all open tabs - info this only triggers for other tabs, not the current one
    //     window.addEventListener('storage', storageEventListener)

    //     return () => {
    //         window.removeEventListener('storage', storageEventListener)
    //     }
    // }, [])

    // useEffect(() => {
    //     localStorage.setItem(STORAGE_KEY, theme)
    //     updateDOM?.()
    // }, [theme])

    return <Script />
}

/**
 * This script is injected as-is into the DOM, therefore it cannot use any outside variables.
 *
 * modifyTransition modifies transition globally to avoid patched transitions.
 *
 * getComputedStyle forces a restyle.
 *
 * taken from https://github.com/vercel/next.js/pull/66926
 **/
export const NoFOUCScript = (storageKey: string) => {
    const modifyTransition = () => {
        const css = document.createElement('style')
        css.textContent = '*,*:after,*:before{transition:none !important;}'
        document.head.appendChild(css)

        return () => {
            getComputedStyle(document.body)
            setTimeout(() => document.head.removeChild(css), 1)
        }
    }

    window.updateDOM = () => {
        const restoreTransitions = modifyTransition()
        const theme = document.cookie
            .split(';')
            .find((c) => c.trim().startsWith(`${storageKey}=`))
            ?.split('=')[1]
        // const theme = localStorage.getItem(storageKey)
        if (theme) {
            document.documentElement.setAttribute('data-wh-theme', theme)
        } else {
            document.documentElement.removeAttribute('data-wh-theme')
        }
        restoreTransitions()
    }
    window.updateDOM()
}

const Script = memo(() => (
    <script
        dangerouslySetInnerHTML={{
            __html: `(${NoFOUCScript.toString()})('${STORAGE_KEY}')`,
        }}
    />
))
