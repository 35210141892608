import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Organisation } from '@bbx/ad-in/organisation/types/DealerProfile'
import { Heading } from '@wh-components/core/Heading/Heading'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { prependHttpIfMissing } from '@wh/common/chapter/lib/urlHelpers'
import styled from 'styled-components'
import dealer_pattern from '@wh/common/chapter/static_hashed/img/dealer-profile/dealerprofiles_wallpaper.png'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'

interface DealerProfileContainerProps {
    organisation: Organisation
}

export const DealerProfileContainer: FunctionComponent<DealerProfileContainerProps> = ({ organisation }) => {
    return (
        <ProfileContainerDealer display="flex" padding="s" marginBottom={{ tablet: 'm' }}>
            <Box
                backgroundColor="semantic.surface"
                borderRadius="m"
                display="flex"
                overflow="hidden"
                flexDirection={{ phone: 'column', tablet: 'row' }}
                width={{ phone: '100%', desktop: 'auto' }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ tablet: 'center' }}
                    borderRight="owl"
                    minWidth={{ tablet: 200 }}
                    minHeight={{ tablet: 200 }}
                    marginTop={{ phone: 'm', tablet: 0 }}
                    marginLeft={{ phone: 'm', tablet: 0 }}
                >
                    {organisation.mainLogoUrls && (
                        <ResponsiveImage src={organisation.mainLogoUrls.large} maxWidth={168} maxHeight={{ phone: 86, tablet: 168 }} />
                    )}
                </Box>
                <Box display="flex" flexDirection="column" padding="m" minWidth={300} maxWidth={{ tablet: 400 }} justifyContent="center">
                    <Heading level={1} text={organisation.displayName} fontSize="l" marginTop="0" marginBottom="xs" />
                    <Box>
                        {organisation.addressDto.addressStreet && <Text>{organisation.addressDto.addressStreet}</Text>}
                        <Box>
                            {organisation.addressDto.addressPostcode && <Text>{organisation.addressDto.addressPostcode} </Text>}
                            {organisation.addressDto.addressTown && <Text>{organisation.addressDto.addressTown}</Text>}
                        </Box>
                    </Box>
                    {organisation.contactDto.url && (
                        <Text as="p">
                            <ServerRoutingAnchorLink
                                type="anchor"
                                rel="nofollow"
                                target="_blank"
                                href={prependHttpIfMissing(organisation.contactDto.url)}
                            >
                                Firmenwebsite
                            </ServerRoutingAnchorLink>{' '}
                        </Text>
                    )}
                    {organisation.contactDto.phone && (
                        <Box>
                            <Text fontWeight="bold">Telefon </Text>
                            <ServerRoutingAnchorLink type="anchor" href={`tel:${organisation.contactDto.phone}`}>
                                {organisation.contactDto.phone}
                            </ServerRoutingAnchorLink>
                        </Box>
                    )}
                    {organisation.contactDto.phone2 && (
                        <Box>
                            <Text fontWeight="bold">Telefon 2 </Text>
                            {organisation.contactDto.phone2}
                        </Box>
                    )}
                </Box>
            </Box>
        </ProfileContainerDealer>
    )
}

export const ProfileContainerDealer = styled(Box)`
    background: url(${dealer_pattern});
    background-color: ${(p) => p.theme.colors.palette.primary.main};
    background-repeat: repeat;
    ${(p) => p.theme.media.tablet} {
        border-radius: ${(p) => p.theme.borderRadii.m}px;
    }
`
