import { getFeatureToggles } from '../../api/togglesApiClient'

const getDataCollectorBaseUrl = async () => {
    const featureToggles = await getFeatureToggles('jobs')
    if (featureToggles.enableDataCollector) {
        return 'https://cltr-dev.willhaben.at'
    } else {
        return 'https://collector.mpianalytics.com'
    }
}

let collectorBaseUrl: string
export let trackerConfig: { collectorBaseUrl: string }

export const initializeConfig = async () => {
    collectorBaseUrl = await getDataCollectorBaseUrl()
    trackerConfig = {
        collectorBaseUrl: collectorBaseUrl,
    }
}

export const configInitialized = initializeConfig()
