import React, { FunctionComponent, PropsWithChildren, useContext } from 'react'

export interface FeatureToggleState {
    toggles: Record<string, boolean>
}

export const FeatureToggleContext = React.createContext<FeatureToggleState>({
    toggles: {},
})

interface FeatureToggleProviderProps extends PropsWithChildren {
    toggles?: Record<FeatureToggle, boolean>
}

export const FeatureToggleProvider: FunctionComponent<FeatureToggleProviderProps> = ({ toggles, children }) => {
    return <FeatureToggleContext.Provider value={{ toggles: toggles || {} }}>{children}</FeatureToggleContext.Provider>
}

export const useFeatureToggle = (featureToggleName: FeatureToggle) => {
    return useContext(FeatureToggleContext).toggles?.[featureToggleName] || false
}

export type FeatureToggle =
    | 'enableUserZoomMyProfile'
    | 'enableUserZoomAutoMotorResultList'
    | 'enableUserZoomAutoMotorAdDetail'
    | 'enableUserZoomAutoMotorStartpages'
    | 'enableUserZoomRealEstateResultList'
    | 'enableUserZoomRealEstateDetailSearch'
    | 'enableUserZoomRealEstateStartpage'
    | 'enableUserZoomRealEstateAdDetail'
    | 'enableUserZoomBapAdDetail'
    | 'enableUserZoomBapResultList'
    | 'enableUserZoomBapStartPage'
    | 'enableUserZoomStartPage'
    | 'enableUserZoomJobAdDetail'
    | 'enableUserZoomJobResultList'
    | 'enableUserZoomJobSearchagents'
    | 'enableUserZoomJobStartPage'
    | 'enableUserZoomJobAdInsertionPage'
    | 'enableUserZoomJobApplicationOverviewPage'
    | 'enableUserZoomAzaNegativePage'
    | 'enableUserZoomAzaPositivePage'
    | 'enableUserZoomFashion'
    | 'enableUserZoomPriceFinder'
    | 'enableUserZoomPersonalization'
    | 'enableShippingCampaign'
    | 'enableHotjarJobAdDetail'
    | 'enableHotjarJobResultList'
    | 'enableHotjarJobSearchagents'
    | 'enableHotjarJobStartPage'
    | 'enableHotjarJobCompanyProfile'
    | 'enableHotJarEditTenantPage'
    | 'enableHotJarTenantCreditCheck'
    | 'enableHotJarViewTenantPage'
    | 'enableHotJarViewLessorRentalAdvertPage'
    | 'enableUserAttributeRecommendation'
    | 'disableZendeskWidget'
    | 'dsaReportingIadEnabled'
    | 'dsaReportingMessagingEnabled'
    | 'enableDsaReportUserProfile'
    | 'enableHotjarJobEmployerPage'
    | 'enableHotjarJobAdInsertionPage'
    | 'enableHotjarJobApplicationOverviewPage'
    | 'enableBudgetCalculatorInFilterRealEstateResultList'
    | 'enableSizePersonalization'
    | 'enableAutoMotorDealerProfile'
    | 'enableAutoMotorRedirectToPersonalizedDealerUrl'
    | 'enableMyTransactionsPage'
    | 'enableMaterialFilterFakeDoorTest'
    | 'enableNewCustomerTestBundle'
    | 'enableDataCollector'
